export default function dataURItoFile(dataURI: string, filename: string): File {
  const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
    ? atob(dataURI.split(',')[1])
    : unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);

  for (let i = 0; i < max; i += 1) {
    ia[i] = bytes.charCodeAt(i);
  }

  return new File(
    [ia],
    filename,
    { type: mime },
  );
}
